'use client'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { useStore } from 'zustand'
import { type MockInterviewStore, createMockInterviewStore } from '@/stores/mock-interview-store'

export type MockInterviewStoreApi = ReturnType<typeof createMockInterviewStore>

export const MockInterviewStoreContext = createContext<MockInterviewStoreApi | undefined>(undefined)

export interface MockInterviewStoreProviderProps {
  children: ReactNode
}

export const MockInterviewStoreProvider = ({ children }: MockInterviewStoreProviderProps) => {
  const storeRef = useRef<MockInterviewStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createMockInterviewStore()
  }

  return (
    <MockInterviewStoreContext.Provider value={storeRef.current}>
      {children}
    </MockInterviewStoreContext.Provider>
  )
}

export const useMockInterviewStore = <T,>(selector: (store: MockInterviewStore) => T): T => {
  const mockInterviewStoreContext = useContext(MockInterviewStoreContext)

  if (!mockInterviewStoreContext) {
    throw new Error(`useMockInterviewStore must be used within MockInterviewStoreProvider`)
  }

  return useStore(mockInterviewStoreContext, selector)
}