'use client'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { useStore } from 'zustand'
import { type JobStore, createJobStore } from '@/stores/job-store'

export type JobStoreApi = ReturnType<typeof createJobStore>

export const JobStoreContext = createContext<JobStoreApi | undefined>(undefined)

export interface JobStoreProviderProps {
  children: ReactNode
}

export const JobStoreProvider = ({ children }: JobStoreProviderProps) => {
  const storeRef = useRef<JobStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createJobStore()
  }

  return (
    <JobStoreContext.Provider value={storeRef.current}>
      {children}
    </JobStoreContext.Provider>
  )
}

export const useJobStore = <T,>(selector: (store: JobStore) => T): T => {
  const jobStoreContext = useContext(JobStoreContext)

  if (!jobStoreContext) {
    throw new Error(`useJobStore must be used within JobStoreProvider`)
  }
  return useStore(jobStoreContext, selector)
}