import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-sans\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/usr/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/usr/app/src/components/layout/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JobStoreProvider"] */ "/usr/app/src/providers/job-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MessageStoreProvider"] */ "/usr/app/src/providers/message-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MockInterviewStoreProvider"] */ "/usr/app/src/providers/mock-interview-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuestionStoreProvider"] */ "/usr/app/src/providers/question-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsStoreProvider"] */ "/usr/app/src/providers/settings-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranscriptionStoreProvider"] */ "/usr/app/src/providers/transcription-store-provider.tsx");
