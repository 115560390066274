"use client";

import React from "react";
import Link from "next/link";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
  BookOpen,
  Briefcase,
  BarChart2,
  Settings,
  FileText,
  MessageSquare,
  UserCircle,
  LogOut,
} from "lucide-react";
import { useAuth } from "@/hooks/useAuth";

const navigationConfig = [
  {
    type: "dropdown",
    label: "Interview Prep",
    icon: BookOpen,
    subItems: [
      {
        href: "/interview/new",
        title: "New Interview Session",
        description: "Start your new interview session.",
        icon: MessageSquare,
      },
      {
        href: "/interview/mock",
        title: "New Mock Interview Session",
        description: "Start your mock interview session.",
        icon: UserCircle,
      },
    ],
  },
  {
    type: "dropdown",
    label: "Job Profile",
    icon: Briefcase,
    subItems: [
      {
        href: "/profile/cv",
        title: "Resume Manager",
        description: "Manage and optimize your CV's.",
        icon: FileText,
      },
      {
        href: "/profile/jobs",
        title: "Job Applications",
        description: "Manage your job applications.",
        icon: Briefcase,
      },
    ],
  },
  {
    type: "link",
    href: "/analytics/interview-history",
    label: "Analytics",
    icon: BarChart2,
  },
  // {
  //   type: "link",
  //   href: "/settings",
  //   label: "Settings",
  //   icon: Settings,
  // },
];

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    icon?: React.ReactNode;
  }
>(({ className, title, children, icon, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="flex items-center gap-2">
            {icon && <span className="h-4 w-4">{icon}</span>}
            <div className="text-sm font-medium leading-none">{title}</div>
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

export const Navigation: React.FC = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {navigationConfig.map((item, index) => (
          <NavigationMenuItem key={index}>
            {item.type === "link" ? (
              <Link href={item.href || ""} legacyBehavior passHref>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "flex items-center space-x-1"
                  )}
                >
                  <item.icon className="w-4 h-4" />
                  <span>{item.label}</span>
                </NavigationMenuLink>
              </Link>
            ) : (
              <>
                <NavigationMenuTrigger className="flex items-center space-x-1">
                  <item.icon className="w-4 h-4" />
                  <span>{item.label}</span>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {item.subItems?.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        href={subItem.href}
                        title={subItem.title}
                        icon={<subItem.icon className="w-4 h-4" />}
                      >
                        {subItem.description}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </>
            )}
          </NavigationMenuItem>
        ))}
        <NavigationMenuItem>
          {user ? (
            <Button
              onClick={() => {
                window.location.href = '/.auth/logout'
              }}
              className={cn(
                navigationMenuTriggerStyle(),
                "flex items-center space-x-1"
              )}
              variant="ghost"
            >
              <LogOut className="w-4 h-4" />
              <span>Sign out</span>
            </Button>
          ) : (
            <Link href="/auth" legacyBehavior passHref>
              <NavigationMenuLink
                className={cn(
                  navigationMenuTriggerStyle(),
                  "flex items-center space-x-1"
                )}
              >
                <UserCircle className="w-4 h-4" />
                <span>Sign In</span>
              </NavigationMenuLink>
            </Link>
          )}
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
