import { createStore } from 'zustand/vanilla';
import { MockInterview, MockInterviewQuestion, InterviewType } from '@prisma/client';
import { 
  getMockInterviews, 
  addMockInterview, 
  updateMockInterview, 
  deleteMockInterview,
  addMockInterviewQuestion,
  updateMockInterviewQuestion,
  deleteMockInterviewQuestion
} from '@/actions/mock-interviews';

export interface MockInterviewState {
  mockInterviews: (MockInterview & { questions: MockInterviewQuestion[] })[];
}

export interface MockInterviewActions {
  fetchMockInterviews: (userId: string) => Promise<void>;
  addMockInterview: (data: {
    jobApplicationId: string;
    interviewType: InterviewType;
    questions: { questionText: string; answer: string }[];
  }) => Promise<MockInterview & { questions: MockInterviewQuestion[] }>;
  updateMockInterview: (id: string, data: Partial<Omit<MockInterview, 'id' | 'createdAt' | 'updatedAt'>>) => Promise<MockInterview & { questions: MockInterviewQuestion[] }>;
  deleteMockInterview: (id: string) => Promise<void>;
  addMockInterviewQuestion: (mockInterviewId: string, data: Omit<MockInterviewQuestion, 'id' | 'mockInterviewId' | 'createdAt' | 'updatedAt'>) => Promise<MockInterviewQuestion>;
  updateMockInterviewQuestion: (id: string, data: Partial<Omit<MockInterviewQuestion, 'id' | 'mockInterviewId' | 'createdAt' | 'updatedAt'>>) => Promise<void>;
  deleteMockInterviewQuestion: (id: string) => Promise<void>;
}

export type MockInterviewStore = MockInterviewState & MockInterviewActions;

const initialState: MockInterviewState = {
  mockInterviews: [],
};

export const createMockInterviewStore = (initState: MockInterviewState = initialState) => 
  createStore<MockInterviewStore>()((set) => ({
    ...initState,

    fetchMockInterviews: async (userId: string) => {
      try {
        const mockInterviews = await getMockInterviews();
        set({ mockInterviews });
      } catch (error) {
        console.error('Failed to fetch mock interviews:', error);
        throw error;
      }
    },

    addMockInterview: async (data) => {
      try {
        const newMockInterview = await addMockInterview(data);
        set((state) => ({
          mockInterviews: [...state.mockInterviews, newMockInterview],
        }));
        return newMockInterview;
      } catch (error) {
        console.error('Failed to add mock interview:', error);
        throw error;
      }
    },

    updateMockInterview: async (id, data) => {
      try {
        const updatedMockInterview = await updateMockInterview(id, data);
        set((state) => ({
          mockInterviews: state.mockInterviews.map((interview) =>
            interview.id === id ? updatedMockInterview : interview
          ),
        }));
        return updatedMockInterview;
      } catch (error) {
        console.error('Failed to update mock interview:', error);
        throw error;
      }
    },

    deleteMockInterview: async (id) => {
      try {
        await deleteMockInterview(id);
        set((state) => ({
          mockInterviews: state.mockInterviews.filter((interview) => interview.id !== id),
        }));
      } catch (error) {
        console.error('Failed to delete mock interview:', error);
        throw error;
      }
    },

    addMockInterviewQuestion: async (mockInterviewId, data) => {
      try {
        const newQuestion = await addMockInterviewQuestion(mockInterviewId, data);
        set((state) => ({
          mockInterviews: state.mockInterviews.map((interview) =>
            interview.id === mockInterviewId
              ? {
                  ...interview,
                  questions: [...interview.questions, newQuestion],
                }
              : interview
          ),
        }));
        return newQuestion;
      } catch (error) {
        console.error('Failed to add mock interview question:', error);
        throw error;
      }
    },

    updateMockInterviewQuestion: async (id, data) => {
      try {
        const updatedQuestion = await updateMockInterviewQuestion(id, data);
        set((state) => ({
          mockInterviews: state.mockInterviews.map((interview) => ({
            ...interview,
            questions: interview.questions.map((q: MockInterviewQuestion) =>
              q.id === id ? { ...q, ...updatedQuestion } : q
            ),
          })),
        }));
      } catch (error) {
        console.error('Failed to update mock interview question:', error);
        throw error;
      }
    },

    deleteMockInterviewQuestion: async (id) => {
      try {
        await deleteMockInterviewQuestion(id);
        set((state) => ({
          mockInterviews: state.mockInterviews.map((interview) => ({
            ...interview,
            questions: interview.questions.filter((q: MockInterviewQuestion) => q.id !== id),
          })),
        }));
      } catch (error) {
        console.error('Failed to delete mock interview question:', error);
        throw error;
      }
    },
  }));