'use client'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { useStore } from 'zustand'
import { type MessageStore, createMessageStore, initMessageStore } from '@/stores/message-store'

export type MessageStoreApi = ReturnType<typeof createMessageStore>

export const MessageStoreContext = createContext<MessageStoreApi | undefined>(undefined)

export interface MessageStoreProviderProps {
  children: ReactNode
}

export const MessageStoreProvider = ({ children }: MessageStoreProviderProps) => {
  const storeRef = useRef<MessageStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createMessageStore(initMessageStore())
  }

  return (
    <MessageStoreContext.Provider value={storeRef.current}>
      {children}
    </MessageStoreContext.Provider>
  )
}

export const useMessageStore = <T,>(selector: (store: MessageStore) => T): T => {
  const messageStoreContext = useContext(MessageStoreContext)

  if (!messageStoreContext) {
    throw new Error(`useMessageStore must be used within MessageStoreProvider`)
  }

  return useStore(messageStoreContext, selector)
}