import { createStore } from 'zustand/vanilla';
import { v4 as uuidv4 } from 'uuid';
import { SupportedModel } from "@/types/models";
import { analyzeTranscript, questionManager } from '@/app/actions';

export interface Question {
  id: string;
  content: string;
  status: "pending" | "processing" | "answered" | "error";
  answer?: string;
  timestamp: number;
}

interface QuestionState {
  questions: Question[];
  transcription: string;
  selectedModel: SupportedModel;
  isAutoAnswerEnabled: boolean;
  autoAnswerThreshold: number;
}

interface QuestionActions {
  addQuestion: (content: string) => void;
  updateQuestion: (updatedQuestion: Question) => void;
  analyzeTranscription: (transcript: string) => Promise<void>;
  refineQuestions: (jobDescription: string, cv: string) => Promise<void>;
  clearQuestions: () => void;
  deleteQuestion: (id: string) => void;
  setSelectedModel: (model: SupportedModel) => void;
  setAutoAnswerEnabled: (enabled: boolean) => void;
  setAutoAnswerThreshold: (threshold: number) => void;
}

export type QuestionStore = QuestionState & QuestionActions;

function normalizeQuestion(question: string): string {
  return question.toLowerCase().trim().replace(/[^\w\s]/g, '');
}

export const initQuestionStore = (): QuestionState => ({
  questions: [],
  transcription: "",
  selectedModel: "gpt-4o-mini",
  isAutoAnswerEnabled: true,
  autoAnswerThreshold: 7,
});

export const createQuestionStore = (initState: QuestionState = initQuestionStore()) => createStore<QuestionStore>()((set, get) => ({
  ...initState,

  addQuestion: (content) =>
    set((state) => ({
      questions: [
        ...state.questions,
        { id: uuidv4(), content, status: "pending", timestamp: Date.now() },
      ],
    })),

  updateQuestion: (updatedQuestion) =>
    set((state) => ({
      questions: state.questions.map((q) =>
        q.id === updatedQuestion.id ? updatedQuestion : q
      ),
    })),

  clearQuestions: () => set({ questions: [] }),

  deleteQuestion: (id) =>
    set((state) => ({
      questions: state.questions.filter((q) => q.id !== id),
    })),

  setSelectedModel: (model) => set({ selectedModel: model }),

  analyzeTranscription: async (transcript: string) => {
    try {
      const { selectedModel, questions } = get()
      const { uniqueQuestions } = await analyzeTranscript(
        transcript,
        selectedModel,
      );      
      const existingQuestions = new Set(questions.map(q => normalizeQuestion(q.content)));
      const now = Date.now();
      
      const newUniqueQuestions = uniqueQuestions
        .filter((q: string) => {
          const normalizedQ = normalizeQuestion(q);
          return normalizedQ !== "" && !existingQuestions.has(normalizedQ);
        })
        .map((q: string) => ({
          id: uuidv4(),
          content: q.trim(),
          status: "pending" as const,
          timestamp: now,
        }));

      if (newUniqueQuestions.length > 0) {
        set((state) => ({
          questions: [...state.questions, ...newUniqueQuestions],
        }));
      }
    } catch (error) {
      console.error("Error analyzing transcription:", error);
    }
  },

  refineQuestions: async (jobDescription: string, cv: string) => {
    try {
      const { transcription, questions, selectedModel } = get();
      const uniqueQuestions = await questionManager(
        transcription,
        questions,
        selectedModel,
        jobDescription,
        cv
      );
      console.log(
        "refined questions from store",
        uniqueQuestions
      );
      set((state) => {
        const existingQuestions = new Set(state.questions.map(q => normalizeQuestion(q.content)));
        const now = Date.now();
        const newUniqueQuestions = uniqueQuestions
          .filter((q: string) => {
            const normalizedQ = normalizeQuestion(q);
            return normalizedQ !== "" && !existingQuestions.has(normalizedQ);
          })
          .map((q: string) => ({
            id: uuidv4(),
            content: q.trim(),
            status: "pending" as const,
            timestamp: now,
          }));

        if (newUniqueQuestions.length > 0) {
          return {
            questions: [...state.questions, ...newUniqueQuestions],
          };
        }
        return state;
      });
    } catch (error) {
      console.error("Error refining questions:", error);
    }
  },
  setAutoAnswerEnabled: (enabled) => set({ isAutoAnswerEnabled: enabled }),
  setAutoAnswerThreshold: (threshold) => set({ autoAnswerThreshold: threshold }),

}));

export const useQuestionStore = createQuestionStore();
