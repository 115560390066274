import { createStore } from 'zustand/vanilla'

export interface Message {
  role: "user" | "assistant";
  content: string;
}

export interface MessageState {
  messages: Message[];
  selectedModel: string;
}

export interface MessageActions {
  addMessage: (message: Message) => void;
  addQuestionAnswer: (question: string, answer: string) => void;
  setSelectedModel: (model: string) => void;
}

export type MessageStore = MessageState & MessageActions

export const initMessageStore = (): MessageState => ({
  messages: [],
  selectedModel: 'gpt-3.5-turbo'
})

export const createMessageStore = (initState: MessageState = initMessageStore()) => {
  return createStore<MessageStore>()((set) => ({
    ...initState,
    addMessage: (message) => set((state) => ({
      messages: [...state.messages, message]
    })),
    addQuestionAnswer: (question, answer) => set((state) => ({
      messages: [
        ...state.messages,
        { role: "user", content: question },
        { role: "assistant", content: answer || "No answer provided." },
      ]
    })),
    setSelectedModel: (model) => set({ selectedModel: model }),
  }))
}